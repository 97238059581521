#tree {
    padding-top: 0px;
  }
  
  #tree .p5Canvas {
    width: 100px !important;
    height: 50% !important;
  }
  
  @media only screen and (max-width: 600px) {
    #tree .p5Canvas {
      width: 150px !important;
      height: 50% !important;
    }
  }