@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
html {
	scroll-behavior: smooth;
	font-family: 'Noto Sans Mono', monospace;
}
.fade-in-section {
	opacity: 0;
	transform: translateY(10vh);
	visibility: hidden;
	transition: opacity 0.6s ease-out, transform 1s ease-out;
	will-change: opacity, visibility;
}
.fade-in-section.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
}
.App {
	font-family: 'Noto Serif';
	background: var(--background);
	display: flex;
  	margin: auto;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  a {
	text-decoration: none !important;
  }
  
  #content {
	/* padding: 80px 200px; */
	width: 100vw;
	overflow-x: hidden;
	overflow-y: hidden;
  }
  .rs-steps-horizontal .rs-steps-item-content {
	text-align: left;
  }
  
  ::selection {
	background: var(--lightest-navy); /* WebKit/Blink Browsers */
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }
  
  .App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-size: calc(10px + 2vmin);
  }
  
  .section-header {
	display: flex;
	padding-bottom: 42px;
	width: 100vw;
  }
  
  .section-header::after {
	content: "";
	display: block;
	width: 300px;
	margin-top: 39px;
	height: 1px;
	margin-left: 20px;
	background-color: var(--text);
  }
  
  .section-title {
	font-family: 'Noto Serif', sans-serif;
	color: var(--lightest-slate);
	font-size: 46px;
	font-weight: bold;
  }
  
  @media only screen and (max-width: 600px) {
	.App {
	  flex-direction: column;
	  padding: 40px 24px;
	}
  
	#content {
	  padding: 10px 10px;
	  width: 100%;
	}
  
	.section-header {
	  width: unset;
	}
  
	.section-header::after {
	  display: none;
	}
  
	.section-title {
	  font-size: 32px;
	}
}