#intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: auto;
	max-width: 1000px;
	min-height: 100vh;

}
.Typist {
	color: var(--lightest-slate);
	text-align: center;
	font-size: 50px;
	margin-top: -30px;
  }
  .Typist .Cursor--blinking {
	color: var(--green-bright);
	padding-left: 4px;
  }
  
  .introTitle {
	font-family: 'Noto Serif', serif;
  	font-size: 60px;
  	color: #BBAB8C;
  }
  
  .introSubtitle {
	margin-top: 5px;
	font-family: 'Noto Serif', sans-serif;
	color: var(--slate);
	text-align: center;
	font-weight: 300;
	font-size: 30px;
  }
  
  .introDescription {
	color: #c62493;
	margin-top: 5px;
	font-family: 'Noto Serif', serif;
	font-size: 22px;
	text-align: center;
	max-width: 600px;
	padding-bottom: 30px;
  }
  
  .introContact {
	font-size: 12px;
	font-weight: bolder;
	font-family: 'Noto Serif', serif;
	padding: 10px 30px;
	cursor: pointer;
	border: 1px solid var(--text);
	border-radius: 4px;
	display: flex;
	align-items: center;
	width: fit-content;
  }
  
  .intro-contact:hover {
	background-color: var(--lightest-navy);
  }
  

@media screen and (max-width: 600px) {
	#intro {
		min-height: unset;
		padding-left: unset;
		padding-top: unset;
		margin: 0;
		align-items: center;
	}
	.Typist {
		font-size: 36px;
	  }
	
	.introSubtitle {
		margin: 0;
		font-size: 28px;
	}
	.introDescription {
		font-size: 18px;
		max-width: unset;
		padding-bottom: 40px;
	}
	.introTitle {
		font-size: 46px;
	}
	.introContact {
		font-size: 16px;
	}
}
