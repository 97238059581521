#uxdesign {
	margin: auto;
	padding-top: 5%;
	padding-bottom: 20px;
	padding-right: 25%;
	max-width: 1000px;
	min-height: 30vh;
	padding-left: 15%;
	}
	
	.uxdesign-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

	}
	@media (max-width: 1080px) {
	.uxdesign-grid {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
}
	.github-icon:hover {
		color: var(--green-bright);
	}

	.spotlight-container{
		background : radial-gradient(#403df4b5, var(--background));
		border-radius: 30px;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 20px;
		padding-top: 40px;
	}
	
	/* Spotlight glider */
	.uxdesign-glide__track {
		align-items: center;
		width: 100%; /* Each slide will take up 25% of the container */
		height: 20%;
		transition: transform 0.9s ease;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		
	  }

	  
	  .glide__slide {
		flex: 0 0 25%; /* Each slide takes up 25% of the container */
		padding: 10px; /* Adjust padding as needed */
		box-sizing:content-box ;
		transition: transform 0.9s ease;
		 /* Replace with your box background color */
		background: linear-gradient(to bottom, #dcd1b7, var(--green-bright)); /* Adjust the gradient colors and opacity as needed */
		border-radius: 40px;


	  }
	  /* spotlight */
	  .spotlight-image{
		width: 100%;
		height: 100%;
		border-radius: 10px;
		
	  }


	@media only screen and (max-width: 600px) {
	#projects {
		height: unset;
		padding-left: unset;
		padding-right: unset;
	}
	.spotlight {
		display: none;
	}
	}

