@font-face {
	font-family: 'Noto Serif', serif;
	src: url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
}
#ai {
	padding-top: 2%;
	padding-left: 15%;
	padding-bottom: 20px;
	padding-right: 20%;

	color: var(--text);
	font-family: 'Noto Serif', serif;
	font-size: 19px;
}
.section-header .section-title {
	font-size: 38px;
	color: #7743DB;
}
.section-header {
	padding-bottom: 20px;
}
.aiDescription ul {
	padding-top: 15px;
	padding-bottom: 15px;
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(2, minmax(150px, 10px));
}

.aiDescription li {
	position: relative;
	padding-left: 20px;
}
.aiDescription li::before {
	content: '▹ ';
	position: absolute;
	left: 0;
	color: var(--highlight);
}

.aiContent {
	display: justify;
	flex-direction: column;
}
.aiDescription {
	/* max-width: ; */
	text-align: justify;
	margin: 0;
}
.aboutImage {
	padding-left: 40px;
}
.aboutImage img {
	max-width: 300px;
	height: auto;
	border-radius: 10px;
	margin-left: 30px;
	box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
	border: none;
}

@media only screen and (max-width: 788px) {
	.aboutImage img {
		max-width: 200px;
	}
}
@media only screen and (max-width: 600px) {
	#about {
		max-width: 100%;
		height: unset;
		padding-left: unset;
		padding-right: unset;
		/* padding-top: 10%; */
		margin: 10%;
	}
	.sectionTitle {
		font-size: 30px !important;
	}
	.aboutDescription {
		font-size: 17px;
		text-align: justify;
	}
	/* .aboutDescription a,
	.aboutDescription b {
		font-size: 20px;
	} */
	.aboutContent {
		display: unset;
	}
	.aboutImage img {
		display: none;
	}
}
