.sidebarNav {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: fixed;
	right: 0;
	padding-right: 5%;
	bottom: 10%;
	background-color: transparent;
}
/* .rs-sidenav-subtle {
	background-color: transparent !important;
} */
.sidebar-links {
	display: flex;
	flex-direction: column;
	text-align: right;
	font-family: 'Noto Serif', serif;
	letter-spacing: 0em;
	line-height: 1.6em;
	font-size: 12px;
	padding: 0.2em;
	font-weight: bold;
}
a {
	text-decoration: none;
	color: var(--highlight);
}
a:hover {
	color: var(--lightest-slate);
}
.sidebarLogos {
	padding-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-bottom: 8px;
}
.logo {
	padding-left: 2px;
}
img {
	height: 18px;
}
@media only screen and (max-width: 600px) {
	.sidebarNav {
		display: none;
	}
}
