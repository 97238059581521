@font-face {
	font-family: 'Noto Serif', serif;
	src: url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');
}
#about {
  min-height: 50vh;
  margin: auto;
  color: #BBAB8C;
  font-family: 'Noto Serif', serif;
  padding-left: 15%;
}

.about-description ul {
  padding-top: 12px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 10px));
}

.about-description li {
  position: relative;
  padding-left: 22px;
  font-size: 18px;
  color: #BBAB8C;
  color: var(--slate);
}

.about-description li::before {
  content: "▹    ";
  position: absolute;
  left: 0;
}

.about-content {
  display: flex;
  flex-direction: row;
}

.about-description {
  font-family: 'Noto Serif', serif;
  max-width: 600px;
  font-size: 18px;
  text-align: justify;
  margin: 0;
}

.about-description a,
.about-description b {
  font-size: 21px;
  font-weight: bold;
}

.about-image {
  padding-left: 80px;
}

.about-image img {
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 15px 16px 50px -5px rgba(113, 8, 166, 0.78);
  border: '1px solid #403df4b5'
}

@media only screen and (max-width: 788px) {
  .about-image img {
    max-width: 200px;
  }
}

.about-image:hover {
  transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
  #about {
    height: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: 30%;
  }

  .about-description {
    font-size: 18px;
    text-align: justify;
  }

  .about-description a,
  .about-description b {
    font-size: 17px;
  }

  .about-image img {
    display: grid;
  }

  .about-content {
    display: unset;
  }
}