:root {
	--dark-navy: #020c1b;
	--navy: #0a192f;
	--light-navy: #112240;
	--lightest-navy: #233554;
	--navy-shadow: rgba(2, 12, 27, 0.7);
	--slate: #648188;
	--light-slate: #7743DB;
	--lightest-slate: #FCB900;
	--white: #e6f1ff;
	--green-bright: #ffa264;
	--green-tint: rgba(100, 255, 218, 0.1);
	/* --background: #FAEED1; */
    --background: radial-gradient(#f7f4ed,  #f1cf785d);
	/* --background: radial-gradient(closest-side, #ffe2afd0, #fdf6e4, #e2260526); */
	--highlight: #809986;
	--text: #BBAB8C;
}
/* #FAEED1
:root {
	--dark-navy: #020c1b;
	--navy: #0a192f;
	--light-navy: #112240;
	--lightest-navy: #233554;
	--navy-shadow: rgba(2, 12, 27, 0.7);
	--slate: #8892b0;
	--light-slate: #a8b2d1;
	--lightest-slate: #ccd6f6;
	--white: #e6f1ff;
	--green-bright: #64ffda;
	--green-tint: rgba(100, 255, 218, 0.1);
	--background: #0a1128;
	--highlight: #d9ed92;
	--text: #778da9;
}*/