#credits {
	display: flex;
	align-items: center;
	padding-top: 100px;
}

.ending-credits {
	font-family: 'Noto Serif', serif;
	color: var(--text);
	text-align: center;
	width: 100%;
	font-size: 16px;
	padding-bottom: 20px;
}

.myDiv {
	padding-bottom: 0px;
	transform: translateY(0);
	background: var(--background);
}