#software {
	margin: auto;
	padding-right: 25%;
	padding-left: 15%;
	max-width: 1300px;
	min-height: 30vh;
	padding-bottom: 40px;
	}
	
	.software-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

	}
	@media (max-width: 1080px) {
	.software-grid {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	}
}
.sectionTitle {
	font-size: 38px;
	color: var(--text);
	
}
.spotlight-container{
	background: radial-gradient(ellipse, #3c39f5 10%, #6b67f7 40%, #f7f4ed 100%);
	border-radius: 40px;
	padding-right: 40px;
	padding-top: 40px;
}
	.github-icon:hover {
		color: var(--green-bright);
	}
	.spotlight-header{
		justify-content: center;
		padding-top: 15px;
		padding-left: 15px;
		padding-right: 25px;
		justify-self: unset;

	}
	.spotlight-card{
		position: relative;
		cursor: default;
		display: flex;
		flex-direction: column;
		height: 20%;
		padding: 2rem 1.75rem;
		background-color: var(--navy);
		border-radius: 30px;

	}


	/* Spotlight glider */
	.glide__track {
		align-items: center;
		width: 100%; /* Each slide will take up 25% of the container */
		height: 20%;
		transition: transform 0.9s ease;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		
	  }

	  
	  .glide__slide {
		flex: 0 0 7%; /* Each slide takes up 25% of the container */
		padding: 10px; /* Adjust padding as needed */
		box-sizing:content-box ;
		transition: transform 0.9s ease;
		 /* Replace with your box background color */
		background: linear-gradient(to bottom, #dcb7db, var(--green-bright)); /* Adjust the gradient colors and opacity as needed */
		border-radius: 40px;


	  }
	  
	  /* spotlight */
	  .spotlight-image{
		width: 100%;
		height: 60%;
		border-radius: 10px;
		
	  }
		.spotlight-image:hover{
			opacity: 0.5;
		}
		.spotlight-title{
			font-family: 'Noto Serif', serif;
			font-weight: bold;
			font-size: 24px;
			color: var(--green-bright);
			padding-top: 10px;
		}
	
		.spotlight-desc{
			font-family: 'Noto Serif', serif;
			padding-top: 30px;
			color: var(--highlight);
			user-select: none;
		}


		.spotlight-tech {
			padding-top: 10px;
			font-family: 'Noto Serif', serif;
			font-size: 14px;
			font-weight: bold;
			color: var(--green-bright);
		}


	@media only screen and (max-width: 600px) {
	#projects {
		height: unset;
		padding-left: unset;
		padding-right: unset;
	}
	.spotlight {
		display: none;
	}
	}

