#MovingRectangles {
	padding-bottom: 20px;
}

#MovingRectangles .p5Canvas {
	width: 250px !important;
	height: 100% !important;
}

@media only screen and (max-width: 600px) {
	#MovingRectangles {
		display: none;
		padding-top: 0x;
	}
}
